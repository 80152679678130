export default class Fade {
    load(el, remove){
        return new Promise(resolve => {
            this.el = el;
            this.remove = remove || false;
            resolve();
        });
    }
    async fadeOut(el, remove) {
        await load(el, remove);
        let op = .2;  // initial opacity
        let timer = setInterval(function () {
            if (op <= 0.1) {
                clearInterval(timer);
                this.el.style.display = 'none';
                if (this.remove) this.el.remove();
                return false;
            }
            this.el.style.opacity = op;
            this.el.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op -= op * 0.1;
        }.bind(this), 50);
    }
    async fadeIn(el, display) {
        await this.load(el);
        let op = .5;  // initial opacity
        let timer = setInterval(function () {
            if (op >= 2) {
                clearInterval(timer);
                return false;
            }
            this.el.style.display = display;
            this.el.style.opacity = op;
            this.el.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op += op * 0.1;
        }.bind(this), 50);
    }
}
