export default class Select {
    constructor() {}

    start() {
        this.select_objs = document.querySelectorAll(".form-group__select");
        Array.prototype.forEach.call(this.select_objs, function(element){
            if (this.isMobileDevice() === true){ //TODO: VERSÃO MOBILE
                element.addEventListener("change", this.MobileEventChange.bind(this), false);
            }else {
                element.addEventListener("mousedown", this.selectEventMousedown.bind(this), false);
            }
        }.bind(this));
    }

    MobileEventChange(evt) {
        if (evt.target.dataset.list !== null) DatalistIn.start(evt.target.dataset.list, evt.target.value);
    }

    selectEventMousedown(evt) {
        evt.preventDefault();
        const el = evt.target;
        const parent = el.closest(".container-select");
        this.custom_option = parent.children[1]; //todo: custom-option
        // const topLeftEl = this.getOffset(parent);

        if (this.custom_option.classList.contains("open")) {
            this.closeOption();
            return false;
        }

        //this.custom_option.setAttribute("style", `display: block;top:calc(${topLeftEl.top}px + ${parent.offsetHeight}px + 5px);left: ${topLeftEl.left}px;width: ${parent.offsetWidth}px`);
        this.custom_option.classList.add("open");
        this.createOption(el);
    }

    createOption(select) {
        //<li class="custom-option__item" data-index="1">Option 1</li>
        this.select = select;
        this.select.classList.add("active");
        const children = select.children,l = select.children.length;
        let i = 0,h=40.2;
        for(i;i<l;i++) {
            this.custom_option.innerHTML += `<li class="custom-option__item" data-index="${i}"><svg class="custom-option__svg"><use xlink:href="${public_url}/_img/sprite.svg#icon-sub"></use></svg>${children[i].innerText}</li>`;
            this.custom_option.style.height = `${h}px`;
            h = h + 40.2;
        }
        this.openOption = this.optionEventClick.bind(this);
        this.formClick = this.formEventClick.bind(this);
        this.custom_option.addEventListener("click", this.openOption, false); //TODO: SELECTION O ITEM DO CUSTOM OPTION
        document.body.addEventListener("click", this.formClick, false); //TODO: EVENTO PRA FECHAR AO CLICAR NO FORM
    }

    formEventClick(evt) {
        evt.preventDefault();
        const el = evt.target;
        if(!el.matches(".form-group__select")) return this.closeOption();
    }

    closeOption() {
        this.custom_option.innerHTML = "";
        this.custom_option.removeAttribute("style");
        this.custom_option.classList.remove("open");
        this.select.classList.remove("active");
        this.custom_option.removeEventListener("click", this.openOption, false);
        document.body.removeEventListener("click", this.formClick, false);
    }

    optionEventClick(evt) { //TODO: SELECIONA ITEM
        const el = evt.target;

        //TODO: PEGA O INDEX DO SELECT CUSTOM E CHAMA METODO {selectOptionIndex}
        if (evt.target.matches(".custom-option__item")) {
            this.selectOptionIndex(parseInt(el.dataset.index));
            this.closeOption();
        }
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    selectOptionIndex(index){
        //TODO: SELECIONA O OPTION INDEX ATRIBUINDO SELECTED
        this.select.options.selectedIndex = index;

        //TODO:VERIFICA SE EXISTE UM DATA-LIST SE SIM CHAMA A CLASSE DATALIST E PASSA O ITEM SELECIONADO
        //console.log(this.select.children[index].value);
        if (this.select.dataset.list !== null) DatalistIn.start(this.select.dataset.list, this.select.children[index].value);

    }

    isMobile() {
        const match = window.matchMedia || window.msMatchMedia;
        if(match) {
            const mq = match("(pointer:coarse)");
            return mq.matches;
        }
        return false;
    }

    isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };
}


// const startSelectOptions = {
//     config: function(options) {
//         new Select(options);
//     }
// };
//
// export {startSelectOptions}
