export default class Switch {

    prevent(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    load(el) {
        return new Promise(resolve => {
            this.switch_name = el;
            this.switch = selector(el);
            this.line = selector(`${el}__line`);
            resolve(true);
        });
    }

    toggle(el) {
        const active = this.switch.querySelector(`${this.switch_name}__title.active`);
        active.classList.remove("active");
        return new Promise(resolve => {
            el.classList.toggle("active");
            resolve();
        });
    }

    async start(el) {
        await this.load(el);
        this.LinePosition(selector(`${this.switch_name}__title.active`));
        this.switch.addEventListener("click", this.SwitchObserver.bind(this));
    }

    async SwitchObserver(evt) {
        //this.prevent(evt);
        if(evt.target.matches(`${this.switch_name}__title`)) {
            await this.toggle(evt.target);
            this.LinePosition(evt.target);
        }
    }

    LinePosition(el) {
        const c = {t: el.offsetTop, l: el.offsetLeft, w: el.offsetWidth, h: el.offsetHeight,};
        this.line.setAttribute("style", `top:${c.t}px;left:${c.l}px;width:${c.w}px;height:${c.h}px;`);
        //el.scrollIntoView({ inline: 'center', behavior: 'smooth', block: 'center'});
        //document.querySelector(".tab-content__item.active").setAttribute("style", "transform: translateX(0);opacity: 1;");
        //this.isDown = false;
    }
}
