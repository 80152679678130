"use strict";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
//import AOS from 'aos';

window.selector = function(className, classNameAll= false) {
    if(classNameAll) return document.querySelectorAll(`${className}`);
    return document.querySelector(`${className}`);
};

import FadeAnimate from "./mods/fade-animate";
import Ajax from "./mods/ajax";
import Fade from "./mods/fade";
import Datalist from "./mods/datalist";
//import {startSelectOptions} from "./mods/select";
import SelectIn from "./mods/select";
import Switch from "./mods/switch";
import SliderRange from "./mods/slider-range";
import Modal from "./mods/modal";

window.FadeAnimate = new FadeAnimate;
window.xhr = new Ajax;
window.Fade = new Fade;
window.DatalistIn = new Datalist;
window.select = new SelectIn;
window.SwitchIn = new Switch;
window.SliderIn = new SliderRange;
window.ModalIn = new Modal;
