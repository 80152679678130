export default class Modal {
    load(el) {
        return new Promise(resolve => {
            this.body = selector("body");
            this.overlay = selector(".modal-overlay");
            this.trigger = selector(el);
            this.modal = selector(this.trigger.dataset.modal);
            this.close = this.modal.querySelector(".modal__close");
            resolve();
        });
    }

    async start(el) {
        await this.load(el);
        this.modal_close = this.CloseModal.bind(this);
        this.trigger.addEventListener("click", this.OpenModal.bind(this), true);
    }

    Toggle() {
        this.body.classList.toggle('modal-open');
        this.modal.classList.toggle("open");
        this.close.addEventListener("click", this.modal_close, false);
        this.overlay.addEventListener("click", this.modal_close, false);
    }

    ToggleDown() {
        this.modal.classList.toggle("down");
    }

    OpenModal(evt) {
        evt.preventDefault();
        this.Toggle();
    }

    CloseModal(evt) {
        evt.preventDefault();
        this.ToggleDown();
        setTimeout(function() {
            this.ToggleDown();
            this.Toggle();
            this.close.removeEventListener("click", this.modal_close, false);
            this.overlay.removeEventListener("click", this.modal_close, false);
        }.bind(this), 200);
    }
}
