export default class FadeAnimate {

    elementScroll(element, x, y) {
        this.el_name = element;
        this.el = selector(element);
        this.el_scroll_to = this.window_scroll_to.bind(this);
        this.x = x || 0;
        this.y = y || 0;
        this.enable = false;
        window.addEventListener("scroll", this.ElementScrollTo.bind(this), false);
    }

    isElementInView(remove) {
        this.remove = remove || false;
        this.elementsToShow = document.querySelectorAll('.animate-scroll');
        this.ElementInView();
        window.addEventListener("scroll", this.EventScrollStart.bind(this), false);
    }

    ElementScrollTo() {
        if(this.pageTop() > 200 && !this.enable) {
            this.el.style.display = "block";
            this.el.addEventListener("pointerdown", this.el_scroll_to, true);
            this.enable = true;
        }

        if(this.pageTop() < 200 && this.enable) {
            this.el.style.display = "none";
            this.enable = false;
            this.el.removeEventListener("pointerdown", this.el_scroll_to, false);
        }
    }

    window_scroll_to(evt) {
        const el = evt.target;
        let x = this.x;
        let y = this.y;

        if(el.matches(this.el_name)) {
            window.scrollTo({
                top: y,
                left: x,
                behavior: 'smooth'
            });
        }else{
            window.scrollTo({
                top: y,
                left: x,
                behavior: 'smooth'
            });
        }

        //document.body.scrollIntoView({ inline: 'center', behavior: 'smooth', block: 'center'})
    }

    EventScrollStart() {
        this.ElementInView();
    }

    ElementInView()
    {
        const pagetop = this.pageBottom();

        Array.prototype.forEach.call(this.elementsToShow, async function(element){
            await this.CheckElementScroll(pagetop, element);
        }.bind(this));
    }

    CheckElementScroll(pagetop, element) {
        return new Promise(resolve => {
            if(pagetop > (element.offsetTop + 50)) {
                element.classList.add('show');
                if (this.remove) {
                    setTimeout(function() {
                        element.classList.remove('animate-scroll');
                        element.classList.remove('show');
                    },8000);
                }
            } else {
                element.classList.remove('show');
            }

            if(this.pageTop() > this.elementBottom(element)) {
                element.classList.remove('show');
            }
        });
    }

    pageBottom() {
        return this.pageTop() + window.innerHeight;
    }

    pageTop() {
        return pageYOffset;
    }

    elementBottom(element) {
        return this.OffsetTop(element) + element.offsetHeight;
    }

    OffsetTop(element) {
        return element.offsetTop;
    }
}
