//todo: Objet create testes
//todo: let dat = {"endereco": "rua jose bonifacio", "title":"muito louco cara"};
/*
 todo: usando fetch
const params = {
"codigo":"9521",
"token":"x>ODFnr}>NN$6w)"
};


const token  = document.head.querySelector('meta[name="csrf-token"]');
const formData = new FormData();
formData.append("username", "Groucho");

//xhr.fetch({url: "/debug/test", method: "POST", body: params, sendType: "json"}, (data) => { // todo: use sending json data
//xhr.fetch({url: "/debug/test", method: "POST", body: formData}, (data) => { // todo: use sending post dataForm()
xhr.fetch({url: "/debug/test/" + "1", method: "PUT", body: params, sendType: "json"}, (data) => {
    console.log(data)
});
*/

export default class Ajax {
    constructor() {}

    // get cpf () {
    //     // verifica se a propriedade não existe no atributo this.data da classe
    //     if (!this.data.hasOwnProperty('cpf')) {
    //         return undefined
    //     }
    //     // retorna o valor da cpf
    //     return this.data.cpf
    // }

    async loadXml() {
        const response = new Promise(resolve => {
            this.xhr = new XMLHttpRequest();
            this.token  = document.head.querySelector('meta[name="csrf-token"]');
            resolve();
        });
        return await response;
        //this.token  = document.querySelector('input[name=_token]').value;
    }

    async serialize(data) {
        this.last_input = "";

        let callback = new Promise(resolve => {
            let tData = {}, isArray = [], max, count = 0;

            for( let name of data.entries() ) {
                if (name[1] !== "") {

                    if (name[0] === this.last_input) {
                        if (count === 0) max = selector(`input[name='${name[0]}']:checked`, true);

                        isArray.push(name[1]);
                        count++;

                        if(count >= max.length -1) {
                            tData[name[0].split("[]")[0]] = isArray;
                            isArray = [];
                            count=0;
                            count="";
                        }
                    }else{
                        if (!name[0].includes("[]")) {
                            tData[name[0]] = name[1];
                        } else {
                            if (count === 0) max = selector(`input[name='${name[0]}']:checked`, true);

                            isArray.push(name[1]);

                            if(max.length === 1) {
                                tData[name[0].split("[]")[0]] = isArray;
                                isArray = [];
                                count=0;
                                count="";
                            }
                        }
                    }

                    this.last_input = name[0];
                }
            }
            resolve(tData);
        });

        return await callback;
    }

    async request(object, callback) {
        await this.loadXml();
        this.method = object.method.toUpperCase();
        this.url = object.url;
        this.log = object.log || false;
        this.data = object.data || null;

        if (this.method === "PUT" ||  this.method === "DELETE") {
            if (this.data !== null) {
                const serialized = await this.serialize(this.data);
                this.data = JSON.stringify(serialized);
            }
        }

        let promise = new Promise((resolve, reject) => {
            this.setOnload(resolve, reject);
            this.setOpen();
            this.setHeader();
            this.setSend(this.data);
        });

        const response = await promise;
        callback(response);
    }

    //todo => Onload response
    setOnload(resolve, reject) {
        this.xhr.onload = function () {
            let response = JSON.parse(this.xhr.response);
            if (this.log) console.log(response);
            if (this.xhr.readyState === 4 && this.xhr.status === 200) {
                resolve(response);
            } else {
                reject(response);
            }
        }.bind(this);
    }

    //todo => initiate AJAX requests
    setOpen() {
        this.xhr.open(this.method, this.url, true);
    }

    //todo => Progress bar events load
    setUpload() {
        this.xhr.upload.addEventListener('progress', (e) => {
            let percent = Math.round(e.loaded * 100 / e.total);
        });
    }

    //todo => Set Headers
    setHeader() {
        this.xhr.setRequestHeader("X-CSRF-TOKEN", this.token.content);
        if (this.method === "PUT" || this.method === "DELETE") this.xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
        if (this.method === "POST" || this.method === "GET") this.xhr.setRequestHeader('Accept','application/json; charset=utf-8');
        //this.xhr.setRequestHeader('Content-type', 'multipart/form-data');
        //this.xhr.setRequestHeader('Content-type', 'text/plain');
        //this.xhr.setRequestHeader("X-Requested-With",'XMLHttpRequest');
        //this.xhr.setRequestHeader("Content-Type",'application/x-www-form-urlencoded');
    }

    //todo => send form
    setSend(data) {
        this.xhr.send(data);
    }

    //TODO FETCH AJAX
    fetchLoad(object) {
        this.token  = document.head.querySelector('meta[name="csrf-token"]');
        this.sendType = object.sendType || null;
        this.method = object.method.toUpperCase();
        this.url = object.url;
        this.log = object.log || false;
        this.body = object.body || null;

        this.headers = new Headers();
        return new Promise(resolve => {
            this.headers.set("X-CSRF-TOKEN", this.token.content);
            if (this.method === "PUT" || this.method === "DELETE") this.headers.set('Content-type','application/json; charset=utf-8');
            if(this.sendType === "JSON" || this.sendType === "json") this.headers.set("Content-Type", "application/json"); //TODO SENDING TO JSON BODY
            //if(this.sendType === null) this.headers.set("accept", "application/json"); // TODO: SENDING TO FORMDATA() AND APPENDS
            //this.headers.set('Content-type','application/json; charset=utf-8');
            resolve();
        });
    }

    checkSendType() {
        return this.sendType === "JSON" || this.sendType === "json";
    }

    async fetch(object, callback) {
        await this.fetchLoad(object);

        //TODO: CHECK SE EXISTE SENDTYPE E SE E JSON
        if(this.checkSendType()) this.body = JSON.stringify(this.body);

        if (this.method === "PUT" ||  this.method === "DELETE") {
            if(!this.checkSendType()) {
                if (this.body !== null) {
                    const serialized = await this.serialize(this.body);
                    this.body = JSON.stringify(serialized);
                }
            }
        }

        try {
            const response = await fetch( this.url, {
                method: this.method,
                headers: this.headers,
                mode: 'cors',
                credentials: "include",
                body: this.body
            });

            let result = await response.json();
            callback(result);
        } catch(err) {
            //callback(err);
            throw err
        }
    }
}
