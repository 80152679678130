export default class SliderRange {

    prevent(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    load(el) {
        return new Promise(resolve => {
            this.el_name = el;
            this.parent = selector(el);
            this.min = 0;
            this.max = 0;
            this.parent.addEventListener("mouseenter", this.ParentHandleClick.bind(this), true);
            this.parent.addEventListener("pointerenter", this.ParentHandleClick.bind(this), true);
            resolve(true);
        });
    }

    start(el) {
        el.filter(async (v)=> {
            await this.load(v);
        });
    }

    ParentHandleClick(evt) {
        this.prevent(evt);
        const self = this;

        //if (evt.target.matches(".group-range__left") || evt.target.matches(".group-range__right")) {
        if (evt.target.matches(".search-range__min") || evt.target.matches(".search-range__max")) {
            this.inputRange = evt.target;
            this.min = this.inputRange.min;
            this.max = this.inputRange.max;
            this.step = this.inputRange.step;

            this.inputUpdateMin = selector(`${evt.target.getAttribute("aria-min")}`);
            this.inputUpdateMax = selector(`${evt.target.getAttribute("aria-max")}`);

            this.inputRange.oninput = function() {
                //UPDATE INPUT RANGE MAX

                if (self.inputRange.classList.contains('max')) {
                    self.checkLimitMin(this, self.inputUpdateMin);
                    self.updateMaxStatus(this, self.inputUpdateMax);
                    return;
                }

                //UPDATE INPUT RANGE MIN
                self.checkLimitMax(this, self.inputUpdateMax);
                self.updateMinStatus(this, self.inputUpdateMin);
            };

        }
    }

    updateMaxStatus(_this, el) {
        el.value = parseInt(_this.value) + el.getAttribute('aria-decimal');
        el.setAttribute("aria-value", `${parseInt(_this.value)}`);
    }

    updateMinStatus(_this, el) {
        el.value = parseInt(_this.value) + el.getAttribute('aria-decimal');
        el.setAttribute("aria-value", `${parseInt(_this.value)}`);
    }


    checkLimitMin(el, min) {
        if(el.value < parseInt(min.getAttribute("aria-value"))) {
            el.value = parseInt(min.getAttribute("aria-value")) + parseInt(this.step);
            return false;
        }
    }

    checkLimitMax(el, max) {
        if(el.value > parseInt(max.getAttribute("aria-value"))) {
            el.value = parseInt(max.getAttribute("aria-value")) - parseInt(this.step);
            return false;
        }
    }
}
