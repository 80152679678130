export default class DataList {

    ajax(datalist, value) {
        return new Promise(resolve => {
            this.datalist = selector(datalist);
            this.container = this.datalist.closest(".form-group");
            this.input = this.container.children[1];
            this.custom_datalist = selector(".custom-datalist");

            xhr.request({url: "../../json/cities/"+ value + ".txt", method: "GET"}, function(data) {
                resolve(data);
            });
        });
    }


    async start(datalist, data) {
        this.options = await this.ajax(datalist, data);
        this.input.value = "";
        if (this.isMobileDevice() === true) {
            this.datalist.innerHTML = this.options
                //.map(o => `<li id=${o.name}>${o.name}</li>`)
                .map(o => `<option value="${o.name}">`)
                .join("");
        }else{
            //this.datalist.empty();
            this.custom_datalist.innerHTML = this.options
                //.map(o => `<li id=${o.name}>${o.name}</li>`)
                .map(o => `<li class="custom-datalist__item">${o.name}</li>`)
                .join("");

            this.input.addEventListener("input", this.InputEvent.bind(this), false);
            this.custom_datalist.addEventListener("click", this.EventSelectOption.bind(this), false);
        }
    }

    create(filter) {
        const filterOptions = this.options.filter(
            d => filter === "" || d.name.toLowerCase().includes(filter.toLowerCase())
        );

        if (filterOptions.length === 0) {
            this.custom_datalist.classList.remove("active");
        } else {
            this.custom_datalist.classList.add("active");
        }

        this.custom_datalist.innerHTML = filterOptions
            .map(o => `<li class="custom-datalist__item">${o.name}</li>`)
            .join("");
    }

    InputEvent(evt) {
        if (!this.container.classList.contains("active")) {
            this.container.classList.add("active");
        }
        this.create(this.input.value);
    }

    EventSelectOption(evt) {
        evt.preventDefault();
        const el = evt.target;
        if(el.matches(".custom-datalist__item")) {
            this.input.value = el.innerText;
            this.custom_datalist.classList.remove("active");
        }
    }

    isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };
}

